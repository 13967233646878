<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    :isLoadingSaveButton="isLoadingSaveButton"
    @save="onSave"
    @cancel="onCancel"
  >
    <template #answers>
      <div class="answers">
        <p class="editable-form__description answers__description">Ответы:</p>
        <div class="input__wrapper">
          <div
            v-for="(item, index) in form.answers"
            :key="item.id"
            class="answers__item"
          >
            <span>{{ index + 1 }}.</span>
            <sm-input v-model="form.answers[index].answer" type="text" />
            <button
              v-show="form.answers.length"
              class="answers__delete-button"
              @click="openDeleteAnswersModal(index)"
            >
              <sm-icon name="trash" />
            </button>
          </div>
          <button class="answers__add-button" @click="onAddAnswer">
            <sm-icon name="plus" />
            Добавить пункт
          </button>
        </div>
      </div>
    </template>
  </sm-editable-item>
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapActions, mapState } from 'vuex';
// components
import smEditableItem from '/src/components/views/SmEditableItem.vue';
import SmIcon from '@/components/common/SmIcon.vue';
import SmInput from '@/components/common/forms/SmInput.vue';

export default {
  name: 'TelemarketingQuestionsForScriptEdit',

  components: {
    smEditableItem,
    SmIcon,
    SmInput,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      previosPageParams: null,
      isLoadingPage: false,
      isLoadingSaveButton: false,
      controllerName: 'TelemarketingQuestions',
      parentName: 'TelemarketingScripts',
      parentItem: null,
      form: {},
      initialForm: null,
      pageHeader: 'Редактирование вопроса по телемаркетингу',
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      telemarketingProducts: (state) => state.common.telemarketingProducts,
      telemarketingScripts: (state) => state.common.telemarketingScripts,
    }),

    id() {
      return +this.previosPageParams.id;
    },

    parentId() {
      return +this.$route.params.parentId;
    },

    scriptName() {
      return this.parentItem?.script ? this.parentItem.script : '';
    },

    breadcrumbs() {
      return [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Скрипты телемаркетинга',
          route: { name: 'TelemarketingScripts' },
        },
        {
          text: `Редактирование скрипта телемаркетинга ${this.scriptName}`,
          route: {
            name: 'TelemarketingScriptEdit',
            params: { id: this.parentId },
          },
        },
        {
          text: 'Вопросы по телемаркетингу',
          route: {
            name: 'TelemarketingQuestionsForScript',
            params: { id: this.parentId },
          },
        },
        {
          text: 'Редактирование вопроса',
        },
      ];
    },

    // initialForm() {
    //   return {
    //     scriptId: this.item?.data?.id ? this.item.data.id : null,
    //     question: null,
    //     answers: [],
    //   };
    // },

    fields() {
      return [
        {
          form: [
            {
              type: 'select',
              key: 'scriptId',
              label: 'Скрипт',
              list: this.telemarketingScripts.data,
              disabled: true,
            },
            {
              type: 'text',
              key: 'question',
              label: 'Вопрос',
            },
            {
              type: 'text',
              key: 'answers',
              label: 'Варианты ответов',
            },
            {
              type: 'checkbox',
              key: 'multipleAnswers',
              label: 'Разрешены множественные ответы',
            },
          ],
        },
      ];
    },

    initialAnswersForm() {
      return {
        id: this.form?.answers?.length + 1,
        answer: null,
      };
    },
  },

  created() {
    this.previosPageParams = this.$route.params;

    this.isLoadingPage = true;

    const parentItem = this.getItem({
      name: this.parentName,
      id: this.parentId,
    }).then((result) => {
      this.parentItem = this.lodash.cloneDeep(result);
    });

    const item = this.getItem({ name: this.controllerName, id: this.id }).then(
      (result) => {
        this.initialForm = this.lodash.cloneDeep(result);
        this.form = this.lodash.cloneDeep(result);
      }
    );

    const telemarketingProducts = this.getCommonList({
      name: 'TelemarketingProducts',
    });
    const telemarketingScripts = this.getCommonList({
      name: 'TelemarketingScripts',
    });

    Promise.all([
      parentItem,
      item,
      telemarketingProducts,
      telemarketingScripts,
    ]).finally(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      getCommonData: 'common/getCommonData',
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
      getCommonList: 'common/getCommonList',
    }),

    onAddAnswer() {
      this.form.answers.push(this.lodash.cloneDeep(this.initialAnswersForm));
    },

    openDeleteAnswersModal(index) {
      this.$root
        .$confirmModal({
          message: 'Удалить пункт?',
          leftButtonText: 'Да',
          rightButtonText: 'Нет',
        })
        .then((response) => {
          if (response) {
            this.form.answers.splice(index, 1);
          }
        });
    },

    onSave() {
      const query = {};
      this.isLoadingSaveButton = true;

      if (
        this.previosPageParams.previosRouteName === 'TelemarketingQuestions'
      ) {
        query.table = JSON.stringify(
          this.previosPageParams.previosRouteQuerySort
        );
        query.filter = JSON.stringify(
          this.previosPageParams.previosRouteQueryFilter
        );
      }

      this.updateItem({
        name: this.controllerName,
        payload: this.form,
      })
        .then((result) => {
          if (result.isSucceed) {
            this.$router.push({
              name: 'TelemarketingQuestionsForScript',
              params: {
                id: this.parentId,
              },
              query,
            });
          }
        })
        .finally(() => {
          this.isLoadingSaveButton = false;
        });
    },

    onCancel() {
      const query = {};

      if (
        this.previosPageParams.previosRouteName ===
        'TelemarketingQuestionsForScript'
      ) {
        query.table = JSON.stringify(
          this.previosPageParams.previosRouteQuerySort
        );
        query.filter = JSON.stringify(
          this.previosPageParams.previosRouteQueryFilter
        );
      }

      if (this.isEqual) {
        this.$router.push({
          name: 'TelemarketingQuestionsForScript',
          params: {
            id: this.parentId,
          },
          query,
        });
        return;
      }

      this.$root
        .$confirmModal({
          message: 'Отменить создание?',
          leftButtonText: 'Да',
          rightButtonText: 'Нет',
        })
        .then((response) => {
          if (response) {
            this.$router.push({
              name: 'TelemarketingQuestionsForScript',
              params: {
                id: this.parentId,
              },
              query,
            });
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.answers {
  width: 100%;

  display: flex;
  align-items: flex-start;
}

.answers__description {
  align-self: flex-start;
}

.answers__item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  .input__wrapper {
    margin-left: 10px;
  }
}

.answers__delete-button {
  color: var(--gray);
  margin-left: 10px;
  &:hover {
    color: var(--red);
  }
}

.answers__add-button {
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 1;
  color: var(--gray);
  border: none;
  background: transparent;
  transition: color 0.3s;
  cursor: pointer;
  margin: 0 0 30px 0;
  &:hover {
    color: var(--blue);
  }
}
</style>
